import { hotjar } from "react-hotjar";

const hotJarTrackingCodVersion = 6;

const initHotJar = () => {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, hotJarTrackingCodVersion);
  hotjar.identify("USER_ID", { userProperty: "value" });
};

export const HotJarService = {
  initHotJar,
};
